import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import ExamLogin from "./component/testseries/examlogin";
import Instructions from "./component/testseries/instructions";
import Test from "./component/testseries/test";
import ThankyouPage from "./component/testseries/thankYouPage";
import ExamScore from "./component/testseries/examScore";
import DisableDevtool from 'disable-devtool';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [examSubmittedState, setExamSubmittedState] = useState(false);
  const [examStartedState, setExamStartedState] = useState(false);
  const [
    candidateLoggedinStateToInstructionsPage,
    setCandidateLoggedinStateToInstructionsPage,
  ] = useState(false);

  useEffect(() => {

    DisableDevtool();
    const examToken = sessionStorage.getItem("examToken");
    const userName = sessionStorage.getItem("userName");
    const examSubmitted = sessionStorage.getItem("examSubmitted");
    const examStarted = sessionStorage.getItem("examStarted");
    const candidateLoggedinToInstructionsPage = sessionStorage.getItem(
      "candidateLoggedinToInstructionsPage"
    );

    if (examToken && userName) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    if (examStarted) {
      setExamStartedState(true);
    }

    if (examSubmitted) {
      setExamSubmittedState(true);
      setIsLoggedIn(false)
    }

    if (candidateLoggedinToInstructionsPage) {
      setCandidateLoggedinStateToInstructionsPage(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (

    <BrowserRouter>
      <Routes>
  
        <Route
          path="/instructions"
          element={isLoggedIn ? <Instructions /> : <Navigate to="/" />}
        />

        <Route
          path="/test"
          element={isLoggedIn ? <Test /> : <Navigate to="/" />}
        />

        <Route
          path="/thankyou"
          element={isLoggedIn ? <ThankyouPage /> : <Navigate to="/" />}
        />

        <Route
          path="/examscore"
          element={isLoggedIn ? <ExamScore /> : <Navigate to="/" />}
        />

        <Route
          path="/"
          element={
            isLoggedIn && examStartedState ? (
              <Navigate to="/test" />
            ) : (
              <ExamLogin onLogin={handleLogin} />
            )
          }
        />

        <Route
          path="/"
          element={
            isLoggedIn && examSubmittedState ? (
              <Navigate to="/thankyou" />
            ) : (
              <ExamLogin onLogin={handleLogin} />
            )
          }
        />

        <Route
          path="/"
          element={
            isLoggedIn && candidateLoggedinStateToInstructionsPage ? (
              <Navigate to="/instructions" />
            ) : (
              <ExamLogin onLogin={handleLogin} />
            )
          }
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
